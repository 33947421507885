<template>
  <div v-if="cases && bands && witnesses">
    <div class="vx-row">
      <div class="vx-col w-full mb-base sm:w-1/1">
        <vx-card :title="$t('analytics.titles.filter')">
          <template v-if="isAdminOrManager">
            <span>{{ $t("analytics.fields.customer") }}</span>
            <v-select
              label="alias"
              :placeholder="$t('analytics.placeholders.customer')"
              :options="customers"
              :clearable="true"
              :reduce="(option) => ({ id: option.id, alias: option.alias })"
              :dir="$vs.rtl ? 'rtl' : 'ltr'"
              v-model="selectedCustomer"
              class="mb-4"
              name="customer"
            />
          </template>
          <template
            v-if="(isAdminOrManager && !!selectedCustomer) || isSuperCustomer"
          >
            <v-select
              :reduce="(option) => option.value"
              :options="[
                { label: $t('analytics.titles.filterByZones'), value: true },
                {
                  label: $t('analytics.titles.filterByEstablishments'),
                  value: false,
                },
              ]"
              :clearable="false"
              v-model="useZones"
              class="mb-4 md:mb-4"
              v-validate="'required'"
              name="useZones"
              v-if="zones.length"
            />

            <template v-if="zones.length && useZones">
              <span>{{ $t("analytics.fields.zones") }}</span>
              <v-select
                label="alias"
                :placeholder="$t('analytics.placeholders.zones')"
                :options="zones"
                :multiple="true"
                :clearable="true"
                :reduce="(option) => ({ id: option.id, alias: option.alias })"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="selectedZones"
                class="mb-4"
                name="zones"
              />
            </template>
            <template v-else>
              <span>{{ $t("analytics.fields.establishments") }}</span>
              <v-select
                label="alias"
                :placeholder="$t('analytics.placeholders.establishments')"
                :options="establishments"
                :multiple="true"
                :clearable="true"
                :reduce="(option) => ({ id: option.id, alias: option.alias })"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
                v-model="selectedEstablishments"
                class="mb-4"
                name="establishments"
              />
            </template>
          </template>
          <span>{{ $t("analytics.fields.period") }}</span>
          <div class="vx-row mb-4">
            <datetime
              v-model="startDate"
              type="date"
              input-class="flatpickr-input vs-input--input"
              format="dd-LL-yyyy"
              :phrases="{
                ok: $t('buttons.ok'),
                cancel: $t('buttons.cancel'),
              }"
              :minute-step="5"
              zone="UTC"
              name="startDate"
              :data-vv-as="$t('analytics.placeholders.startDate')"
              :placeholder="$t('analytics.placeholders.startDate')"
              class="vx-col sm:w-1/1 w-1/2"
            >
              <template slot="after">
                <button
                  class="datetime-remove-button"
                  @click="startDate = null"
                  v-if="(startDate || null) !== null"
                >
                  <feather-icon icon="XIcon" svgClasses="h-4 w-4" />
                </button>
              </template>
            </datetime>
            <datetime
              v-model="endDate"
              type="date"
              input-class="flatpickr-input vs-input--input"
              format="dd-LL-yyyy"
              :phrases="{
                ok: $t('buttons.ok'),
                cancel: $t('buttons.cancel'),
              }"
              :minute-step="5"
              zone="UTC"
              name="endDate"
              :data-vv-as="$t('analytics.placeholders.endDate')"
              :placeholder="$t('analytics.placeholders.endDate')"
              class="vx-col sm:w-1/1 w-1/2"
            >
              <template slot="after">
                <button
                  class="datetime-remove-button"
                  @click="endDate = null"
                  v-if="(endDate || null) !== null"
                >
                  <feather-icon icon="XIcon" svgClasses="h-4 w-4" />
                </button>
              </template>
            </datetime>
          </div>
          <vs-button
            color="primary"
            @click="$refs.html2Pdf.generatePdf()"
            icon-pack="feather"
            icon="icon-bar-chart"
          >
            {{ $t("analytics.buttons.exportPDF") }}
          </vs-button>
        </vx-card>
      </div>
    </div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="800"
      :filename="pdfFileName"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="20cm"
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <div class="vx-row mx-4 text-xs">
          <div class="vx-col w-full mb-base sm:w-1/1 md:w-1/1 text-center my-4">
            <h1>{{ pdfFileName }}</h1>
          </div>
          <template v-for="(report, pos) in reports">
            <template v-if="report.type === 'page-break'">
              <div
                class="w-full html2pdf__page-break"
                :key="`pdf_report_separator_${pos}`"
              ></div>
              <div class="w-full mb-22" :key="`pdf_report_margin_${pos}`">
                &nbsp;
              </div>
            </template>
            <Report
              v-else
              :report="report"
              :key="`pdf_report_${pos}`"
              :isForPDF="true"
            />
          </template>
        </div>
      </section>
    </vue-html2pdf>
    <div class="vx-row">
      <Report
        :report="report"
        v-for="(report, pos) in reports.filter((x) => x.type !== 'page-break')"
        :key="`report_${pos}`"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import VueHtml2pdf from "vue-html2pdf";

import Report from "./helpers/Report.vue";
import { db } from "@/firebase/firebaseConfig";

import * as ReportGenerator from "@/utils/reportGenerator.js";
import vSelect from "vue-select";

import "vue-datetime/dist/vue-datetime.css";
import { Datetime } from "vue-datetime";

import fcseReasons from "@/data/cases/lists/fcseReasons.json";

import {
  generateWidgetReport,
  generateDonutReport,
  generateBarReport,
} from "@/utils/reportGenerator.js";
import moment from "moment";

const MAX_BAR_SIZE = 10;

export default {
  name: "Dashboard",
  components: {
    vSelect,
    Report,
    Datetime,
    VueHtml2pdf,
  },
  computed: {
    ...mapGetters([
      "allCases",
      "allIssues",
      "allCustomers",
      "allEstablishments",
      "allZones",
    ]),

    isForIssues() {
      return this.$route.meta.isForIssues || false;
    },
    isAdminOrManager() {
      return ["admin", "manager"].includes(
        this.$store.state.AppActiveUser.role
      );
    },

    isCustomer() {
      return this.$store.state.AppActiveUser.role === "customer";
    },
    isSuperCustomer() {
      return this.$store.state.AppActiveUser.role === "supercustomer";
    },

    customers() {
      return this.allCustomers
        ? this.allCustomers.filter((c) =>
            c.casesTypes.map((c) => c.id).includes("penal")
          )
        : [];
    },

    establishments() {
      return (
        this.allEstablishments
          ? this.isSuperCustomer
            ? this.allEstablishments
            : this.allEstablishments.filter(
                (e) => e.customer.id === this.selectedCustomer.id
              )
          : []
      ).map((x) => {
        return x.id === "empresa"
          ? {
              ...x,
              id: x.id,
              alias: this.$t("establishments.labels.wholeCompany"),
            }
          : x;
      });
    },

    zones() {
      return this.allZones
        ? this.isSuperCustomer
          ? this.allZones
          : this.allZones.filter(
              (e) => e.customer.id === this.selectedCustomer.id
            )
        : [];
    },

    allItems() {
      return ((this.isForIssues ? this.allIssues : this.allCases) || []).map(
        (x) => ({
          id: x.id,
          ...x,
          caseStatus: this.isForIssues ? null : x.caseStatus,
        })
      );
    },

    filteredItems() {
      let items = this.allItems;

      if (this.selectedCustomer) {
        items = items.filter((x) => x.customer.id === this.selectedCustomer.id);
      }

      if (this.selectedZones && this.selectedZones.length) {
        let establishmentsIds = [].concat(
          ...this.allZones
            .filter((z) => this.selectedZones.map((x) => x.id).includes(z.id))
            .map((x) => x.establishments)
        );
        items = items.filter((x) =>
          establishmentsIds.includes(x.establishment.id)
        );
      }

      if (this.selectedEstablishments && this.selectedEstablishments.length) {
        items = items.filter((x) =>
          this.selectedEstablishments
            .map((x) => x.id)
            .includes(x.establishment.id)
        );
      }

      return items;
    },

    suspensions() {
      let suspensions = this.filteredItems.filter(
        (x) => !!x.trialStatus && x.trialStatus.alias === "Suspendido"
      );

      if (this.startDate) {
        suspensions = suspensions.filter((x) =>
          moment(x.datetime.toDate()).isAfter(moment(this.startDate))
        );
      }
      if (this.endDate) {
        suspensions = suspensions.filter((x) =>
          moment(x.datetime.toDate()).isBefore(
            moment(this.endDate).add(1, "day")
          )
        );
      }
      return suspensions;
    },

    cases() {
      let cases = this.filteredItems;

      if (this.startDate) {
        cases = cases.filter((x) =>
          moment(x.datetime.toDate()).isAfter(moment(this.startDate))
        );
      }
      if (this.endDate) {
        cases = cases.filter((x) =>
          moment(x.datetime.toDate()).isBefore(
            moment(this.endDate).add(1, "day")
          )
        );
      }
      return cases;
    },

    trials() {
      let trials = this.filteredItems.filter((x) => !!x.trialDatetime);

      if (this.startDate) {
        trials = trials.filter((x) =>
          moment(x.trialDatetime.toDate()).isAfter(moment(this.startDate))
        );
      }
      if (this.endDate) {
        trials = trials.filter((x) =>
          moment(x.trialDatetime.toDate()).isBefore(
            moment(this.endDate).add(1, "day")
          )
        );
      }
      return trials;
    },

    pdfFileName() {
      let pdfFileName = `Análisis`;
      if (this.selectedCustomer) {
        pdfFileName += ` ${this.selectedCustomer.alias}`;
      }
      if (this.startDate) {
        pdfFileName += ` desde ${moment(this.startDate).format("DD-MM-YYYY")}`;
      }
      if (this.endDate) {
        pdfFileName += ` hasta ${moment(this.endDate).format("DD-MM-YYYY")}`;
      }
      return pdfFileName;
    },

    reports() {
      let reports = [];

      //Casos en el periodo

      reports.push({
        id: "casesInPeriod",
        size: this.isForIssues ? "1/3" : "1/4",
        type: "widget",
        icon: "ArchiveIcon",
        color: "primary",
        value: this.cases.length,
      });

      //Denuncias Bandas Organizadas
      reports.push({
        id: "organizedBands",
        size: this.isForIssues ? "1/3" : "1/4",
        type: "widget",
        icon: "UsersIcon",
        color: "danger",
        value: generateWidgetReport(
          this.cases,
          (c) =>
            this.bands.filter(
              (b) => b[this.isForIssues ? "issueId" : "caseId"] === c.id
            ).length > 0
        ),
      });

      //RC Reclamada
      const withRC = this.cases.filter((c) => !!c.companyCivilLiabilityAmount);
      if (!this.isForIssues) {
        reports.push({
          id: "claimedCL",
          size: "1/4",
          type: "widget",
          icon: "DollarSignIcon",
          color: "success",
          value: withRC
            .map((c) => c.companyCivilLiabilityAmount)
            .reduce((a, b) => a + b, 0),
        });
      }

      //Ticket medio
      const withTickets = this.cases.filter((c) => !!c.ticketAmount);
      reports.push({
        id: "avgTicket",
        size: this.isForIssues ? "1/3" : "1/4",
        type: "widget",
        icon: "DollarSignIcon",
        color: "danger",
        value: withTickets.length
          ? parseFloat(
              (
                withTickets
                  .map((c) => c.ticketAmount)
                  .reduce((a, b) => a + b, 0) / withTickets.length
              ).toFixed(2)
            )
          : 0,
      });

      //Casos totales por establecimientos
      const casesByEstablismentMap = new Map();
      this.cases.forEach((x) => {
        if (casesByEstablismentMap.has(x.establishment.id)) {
          casesByEstablismentMap.get(x.establishment.id).value += 1;
        } else {
          casesByEstablismentMap.set(x.establishment.id, {
            id: x.establishment.id,
            alias: x.establishment.alias,
            value: 1,
          });
        }
      });

      let casesByEstablisment = [...casesByEstablismentMap.values()].sort(
        (a, b) => b.value - a.value
      );

      let casesSeries = casesByEstablisment.map((x) => ({
        title: x.alias,
        value: parseFloat(x.value.toFixed(2)),
      }));

      reports.push({
        id: "casesPerEstablishment",
        size: "1/1",
        type: "bar",
        series: casesSeries,
        total: casesSeries.map((x) => x.value).reduce((a, b) => a + b, 0),
      });

      reports.push({ type: "page-break" });

      //RC por establecimientos
      const establishmentsWithRCMap = new Map();
      withRC.forEach((x) => {
        if (establishmentsWithRCMap.has(x.establishment.id)) {
          establishmentsWithRCMap.get(x.establishment.id).value +=
            x.companyCivilLiabilityAmount;
        } else {
          establishmentsWithRCMap.set(x.establishment.id, {
            id: x.establishment.id,
            alias: x.establishment.alias,
            value: x.companyCivilLiabilityAmount,
          });
        }
      });

      let establishmentsWithRC = [...establishmentsWithRCMap.values()].sort(
        (a, b) => b.value - a.value
      );
      if (establishmentsWithRC.length > MAX_BAR_SIZE - 1) {
        const others = establishmentsWithRC
          .slice(MAX_BAR_SIZE - 1)
          .map((x) => x.value);
        establishmentsWithRC = establishmentsWithRC.slice(0, MAX_BAR_SIZE - 1);
        establishmentsWithRC.push({
          id: "OTHERS",
          alias: "Otros",
          value: others.reduce((a, b) => a + b, 0),
        });
      }

      let RCSeries = establishmentsWithRC.map((x) => ({
        title: x.alias,
        value: parseFloat(x.value.toFixed(2)),
      }));

      if (!this.isForIssues) {
        reports.push({
          id: "CLPerEstablishment",
          size: "1/3",
          PDFSize: "1/2",
          type: "bar",

          series: RCSeries,
          total: RCSeries.map((x) => x.value).reduce((a, b) => a + b, 0),
        });
      }

      //Tickets por establecimientos
      const establishmentsWithTicketsMap = new Map();
      withTickets.forEach((x) => {
        if (establishmentsWithTicketsMap.has(x.establishment.id)) {
          establishmentsWithTicketsMap.get(x.establishment.id).value +=
            x.ticketAmount;
        } else {
          establishmentsWithTicketsMap.set(x.establishment.id, {
            id: x.establishment.id,
            alias: x.establishment.alias,
            value: x.ticketAmount,
          });
        }
      });

      let establishmentsWithTickets = [
        ...establishmentsWithTicketsMap.values(),
      ].sort((a, b) => b.value - a.value);
      if (establishmentsWithTickets.length > MAX_BAR_SIZE - 1) {
        const others = establishmentsWithTickets
          .slice(MAX_BAR_SIZE - 1)
          .map((x) => x.value);
        establishmentsWithTickets = establishmentsWithTickets.slice(
          0,
          MAX_BAR_SIZE - 1
        );
        establishmentsWithTickets.push({
          id: "OTHERS",
          alias: "Otros",
          value: others.reduce((a, b) => a + b, 0),
        });
      }

      let ticketSeries = establishmentsWithTickets.map((x) => ({
        title: x.alias,
        value: parseFloat(x.value.toFixed(2)),
      }));

      reports.push({
        id: "ticketsPerEstablishment",
        size: this.isForIssues ? "1/2" : "1/3",
        PDFSize: "1/2",
        type: "bar",

        series: ticketSeries,
        total: ticketSeries.map((x) => x.value).reduce((a, b) => a + b, 0),
      });

      //Importe total tickets
      let ticketsAmountsOptions = [
        { id: "recovered", color: "#28C76F" },
        { id: "claimed", color: "#EA5455" },
      ];
      let ticketsAmountsPie = generateDonutReport(
        this.cases,
        this.translate(ticketsAmountsOptions, "ticketsTotalAmount"),
        null,
        (c) =>
          c.recoveredProducts && c.recoveredProducts.id === "ztzavcczhbucxqqo"
            ? 1
            : 2, // 1: Importe recuperado en tienda, 2: Importe reclamado
        (c) => c.ticketAmount || 0,
        (val) => `${val.toFixed(2)} EUROS`,
        "pie"
      );

      reports.push({
        id: "ticketsTotalAmount",
        size: this.isForIssues ? "1/2" : "1/3",
        PDFSize: "1/2",
        type: "pie",

        options: ticketsAmountsPie.options,
        series: ticketsAmountsPie.series,
      });

      if (!this.isForIssues) {
        //Denuncias tramitadas
        let complaintOptions = [
          { id: "ordinary", color: "#7961F9" },
          { id: "JIDL", color: "#FF9F43" },
          { id: "policeStation", color: "#EA5455" },
          { id: "establishment", color: "#28C76F" },
        ];
        let complaintCriteria = (c) => {
          if (!!c.processing && c.processing.id === "NPcUeCG86RDSw6bywfOF") {
            return 1; //ORDINARIO
          } else if (
            !!c.processing &&
            c.processing.id === "StQuaYZl4JTcL225yY3C"
          ) {
            return 2; //JIDL
          } else if (!c.complaintAtEstablishment) {
            return 3; //Comisaria
          } else {
            return 4; //Establecimiento
          }
        };

        let allComplaints = generateDonutReport(
          this.cases,
          this.translate(complaintOptions, "processedComplaints"),
          null,
          complaintCriteria,
          null,
          null
        );

        reports.push({
          id: "processedComplaints",
          size: "1/2",
          type: "donut",

          options: allComplaints.options,
          series: allComplaints.series,
        });

        //Denuncias
        let penalTypeOptions = [
          { id: "thefts", color: "#7961F9" },
          { id: "riskComplaints", color: "#FF9F43" },
          { id: "minors", color: "#EA5455" },
          { id: "crimes", color: "#28C76F" },
        ];
        let penalTypeCriteria = (c, typePos) => {
          if (typePos === 1) {
            //Hurtos
            return (
              !!c.penalType &&
              c.penalType.length > 0 &&
              !!c.penalType.find((p) =>
                [
                  "q6hZJGTzpNOzs3AOd9nr",
                  "Su2zY3b2hzPkxA3EIVpu",
                  "ZHK5ATuZFQ49ZvBDCYZM",
                ].includes(p.id)
              )
            );
          } else if (typePos === 2) {
            //Denuncias Riesgo
            return (
              !!c.penalType &&
              c.penalType.length > 0 &&
              !!c.penalType.find((p) =>
                [
                  "PqB1nSSHbTM6do6vkibO",
                  "OZLITkhYZNy5KP4i9FYO",
                  "ALJ6PefKbE2PvhPJYaZ0",
                ].includes(p.id)
              )
            );
          } else if (typePos === 3) {
            //Menores
            return c.minors;
          } else if (typePos === 4) {
            //Delitos
            return (
              !!c.crimeType &&
              !["jYjBxN3U8yyT8VvrU7iQ"].includes(c.crimeType.id)
            );
          }
        };

        let penalTypes = generateBarReport(
          this.cases,
          this.translate(penalTypeOptions, "complaints"),
          null,
          penalTypeCriteria
        );
        reports.push({
          id: "complaints",
          size: "1/2",
          PDFSize: "1/1",
          type: "bar",

          series: penalTypes.series,
          total: penalTypes.total,
        });

        reports.push({ type: "page-break" });

        //Juicios celebrados
        reports.push({
          id: "trialsDone",
          size: "1/4",
          type: "widget",
          icon: "BriefcaseIcon",

          color: "success",
          value: this.trials.length,
        });

        //Juicios suspendidos
        reports.push({
          id: "trialsSuspended",
          size: "1/4",
          type: "widget",
          icon: "CalendarIcon",

          color: "warning",
          value: this.suspensions.length,
        });

        //Sentencias
        let allSentences = generateWidgetReport(
          this.cases,
          (c) => !!c.judgmentType
        );
        reports.push({
          id: "judgments",
          size: "1/4",
          type: "widget",
          icon: "FileTextIcon",

          color: "warning",
          value: allSentences,
        });

        //Órdenes de alejamiento
        let restrainingOrder = generateWidgetReport(
          this.cases,
          (c) => c.restrainingOrder
        );
        reports.push({
          id: "restrainingOrders",
          size: "1/4",
          type: "widget",
          icon: "CalendarIcon",

          color: "primary",
          value: restrainingOrder,
        });

        //Sentencias
        let sentenceOptions = [
          { id: "damning", color: "#28C76F" },
          { id: "acquittals", color: "#EA5455" },
        ];
        let sentencesCriteria = (c) => {
          if (
            !!c.judgmentType &&
            c.judgmentType.id === "fPa6sb3yrMS4lSTExr2K"
          ) {
            //Sentencia Condenatoria
            return 1; //Condenatoria
          } else if (
            !!c.judgmentType &&
            c.judgmentType.id === "IZgr5sCvF7xDgVYH7Ppa" //Condena Absolutoria
          ) {
            return 2; //Absolutoria
          }
        };
        let sentences = generateDonutReport(
          this.cases,
          this.translate(sentenceOptions, "judgmentsPie"),
          null,
          sentencesCriteria,
          null,
          null,
          "pie"
        );

        reports.push({
          id: "judgmentsPie",
          size: "1/3",
          PDFSize: "1/2",
          type: "pie",

          options: sentences.options,
          series: sentences.series,
        });

        //Responsabilidad civil
        let civilLiabilityOptions = [
          { id: "withCL", color: "#28C76F" },
          { id: "withoutCL", color: "#EA5455" },
        ];
        let civilLiabilityCriteria = (c) => {
          if (
            c.recoveredProducts &&
            c.recoveredProducts.id === "ztzavcczhbucxqqo"
          ) {
            return 1; //Casos con responsibilidad civil
          } else {
            return 2; //Casos sin responsibilidad civil
          }
        };
        let civilLiability = generateDonutReport(
          this.cases,
          this.translate(civilLiabilityOptions, "civilLiability"),
          null,
          civilLiabilityCriteria,
          null,
          null,
          "pie"
        );

        reports.push({
          id: "civilLiability",
          size: "1/3",
          PDFSize: "1/2",
          type: "pie",
          options: civilLiability.options,
          series: civilLiability.series,
        });

        //Denuncias
        let complaintTypesOptions = [
          { id: "aggression" },
          { id: "threats" },
          { id: "undocumentedCriminal" },
          { id: "notRetained" },
          { id: "crime" },
          { id: "lackOfTroops" },
          { id: "lackOfAgreement" },
          { id: "minors" },
          { id: "FCSEError" },
        ];
        let complaintTypesCriteria = (c, typePos) => {
          if (!c.fcseReason) return;

          const reason = fcseReasons.find((x) => x.id === c.fcseReason.id);

          if (!reason) return;

          return complaintTypesOptions[typePos - 1].id === reason.key;
        };

        let complaintTypes = generateBarReport(
          this.cases,
          this.translate(complaintTypesOptions, "complaintsAtPoliceStation"),
          null,
          complaintTypesCriteria
        );
        reports.push({
          id: "complaintsAtPoliceStation",
          size: "1/3",
          PDFSize: "1/1",
          type: "bar",
          series: complaintTypes.series,
          total: complaintTypes.total,
        });

        reports.push({ type: "page-break" });

        //Recuperación pérdidas
        let restoreLostsOptions = [
          { id: "accountDeposit", color: "#7961F9" },
          { id: "paymentOrder", color: "#FF9F43" },
        ];
        let restoreLostsCriteria = (c) => {
          if (!c.enforcementPaymentMethod) return;
          if (c.enforcementPaymentMethod.id === "qC4YbW8bsUfJwZrzRlaQ") {
            return 1; //Ingreso en cuenta
          } else if (c.enforcementPaymentMethod.id === "OAdeBOPizGn60BuYjiq4") {
            return 2; //Mandamiento de pago
          }
        };
        let restoreLosts = generateDonutReport(
          this.cases,
          this.translate(restoreLostsOptions, "lossRecoveryMethod"),
          null,
          restoreLostsCriteria,
          null,
          null,
          "pie"
        );

        reports.push({
          id: "lossRecoveryMethod",
          size: "1/4",
          PDFSize: "1/2",
          type: "pie",

          options: restoreLosts.options,
          series: restoreLosts.series,
        });
      }

      const hourOptions = [
        { id: "morning", color: "#7961F9" },
        { id: "noon", color: "#FF9F43" },
        { id: "afternoon", color: "#EA5455" },
        { id: "night", color: "#28C76F" },
      ];

      reports.push({
        id: "hours",
        size: this.isForIssues ? "1/2" : "1/4",
        PDFSize: "1/2",
        ...ReportGenerator.hoursReport(
          this.cases,
          this.translate(hourOptions, "hours")
        ),
      });

      if (!this.isForIssues) {
        reports.push({
          id: "crimeTypes",
          size: "1/4",
          PDFSize: "1/2",
          ...ReportGenerator.crimeTypesReport(this.cases, this.enumTranslate),
        });
      }

      const witnessesOptions = [
        { id: "withWitnesses", color: "#28C76F" },
        { id: "withoutWitnesses", color: "#EA5455" },
      ];

      reports.push({
        id: "casesWithWitnesses",
        size: this.isForIssues ? "1/2" : "1/4",
        PDFSize: "1/2",

        ...ReportGenerator.witnessReport(
          this.cases,
          this.witnesses,
          this.translate(witnessesOptions, "casesWithWitnesses")
        ),
      });

      reports.push({ type: "page-break" });

      if (!this.isForIssues) {
        reports.push({
          id: "penalType",
          size: "1/2",

          ...ReportGenerator.penalTypesReport(this.cases, this.enumTranslate),
        });
      }

      let customerId =
        this.isCustomer || this.isSuperCustomer
          ? this.$store.state.AppActiveUser.customer.id
          : null;

      if (!this.isForIssues) {
        reports.push({
          id: "caseStatus",
          size: "1/2",
          ...ReportGenerator.statusReport(
            customerId,
            this.cases,
            this.enumTranslate
          ),
        });
      }

      reports.push({ type: "page-break" });

      reports.push({
        id: "criminalsParticipationType",
        size: "1/3",
        PDFSize: "1/2",

        ...ReportGenerator.customDonutReport(
          this.cases,
          "theftCount",
          (options) => {
            return options
              .filter((o) => o.id !== "NS_NC")
              .map((o) => ({
                realId: o.id,
                id: o.id,
                key: o.key,
                title: this.enumTranslate("theftCount", o.key),
              }));
          },
          "pie"
        ),
      });

      reports.push({
        id: "repetition",
        size: "1/3",
        PDFSize: "1/2",
        ...ReportGenerator.customDonutReport(
          this.cases,
          "regularInArea",
          (options) => {
            return options
              .filter((o) => o.id !== "NS_NC")
              .map((o) => ({
                realId: o.id,
                id: o.id,
                key: o.key,
                title:
                  o.key === "SI__PERSONA_CONOCIDA_REINCIDENTE"
                    ? this.$t("boolean.yes")
                    : this.enumTranslate("regularInArea", o.key),
              }));
          },
          "pie"
        ),
      });

      reports.push({
        id: "actuationType",
        size: "1/3",
        PDFSize: "1/2",

        ...ReportGenerator.customDonutReport(
          this.cases,
          "theftSeen",
          (options) => {
            return this.translate(
              options
                .filter((o) => o.id !== "NS_NC")
                .map((o) => ({
                  key: o.key,
                  title: this.enumTranslate("theftSeen", o.key),
                  realId: o.id,
                  id:
                    o.key ===
                    "SI_LO_HEMOS_VISTO_Y_LLAMADO_A_LA_POLICIA_VIGILANTE"
                      ? "retainedInStore"
                      : o.key === "SI__LO_HEMOS_VISTO_PERO_NO_LO_HEMOS_RETENIDO"
                      ? "escapes"
                      : o.key ===
                        "NO_LO_HEMOS_VISTO_PERO_HEMOS_DETECTADO_FALTA_DE_ARTICULOS"
                      ? "posterior"
                      : "police",
                })),
              "actuationType"
            );
          },
          "pie"
        ),
      });

      reports.push({
        id: "stolenItems",
        size: "1/3",
        PDFSize: "1/2",

        ...ReportGenerator.customDonutReport(
          this.cases,
          "looseArticles",
          (options) => {
            return this.translate(
              options
                .filter((o) => o.id !== "NS_NC")
                .map((o) => ({
                  key: o.key,
                  title: this.enumTranslate("looseArticles", o.key),
                  realId: o.id,
                  id:
                    o.key === "SI__GRAN_CANTIDAD__MAS_DE_4_ARTICULOS_"
                      ? "moreThan4"
                      : "lessThan4",
                })),
              "stolenItems"
            );
          },
          "pie"
        ),
      });

      reports.push({
        id: "modusOperandi",
        size: "1/3",
        PDFSize: "1/2",

        ...ReportGenerator.customDonutReport(
          this.cases,
          "theftMethod",
          (options) => {
            return this.translate(
              options.map((o) => ({
                key: o.key,
                title: this.enumTranslate("theftMethod", o.key),
                realId: o.id,
                id:
                  o.key === "BOLSA_PREPARADA"
                    ? "linedBag"
                    : o.key === "DESALARMADOR"
                    ? "disarming"
                    : o.key === "HA_UTILIZADO_EL_PROBADOR_PARA_PREPARARSELO"
                    ? "fittingRoom"
                    : o.key ===
                      "PASO_LOS_ARTICULOS_POR_ENCIMA_DEL_ARCO_DE_SEGURIDAD"
                    ? "securityArch"
                    : o.key === "HAN_SONADO_LOS_ARCOS_DE_SEGURIDAD"
                    ? "archesSound"
                    : o.key === "OCULTA_ENTRE_SUS_PERTENENCIAS"
                    ? "hidden"
                    : o.key === "EMPLEA_FUERZA_PARA_LLEVARSELOS"
                    ? "force"
                    : "others",
              })),
              "modusOperandi"
            );
          },
          "pie"
        ),
      });

      reports.push({
        id: "caseWithImages",
        size: "1/3",
        PDFSize: "1/2",

        ...ReportGenerator.customDonutReport(
          this.cases,
          "hasImages",
          (options) => {
            return this.translate(
              options.map((o) => ({
                key: o.key,
                title: this.enumTranslate("hasImages", o.key),
                realId: o.id,
                id:
                  o.key ===
                  "Si__tenemos_las_imagenes_guardadas_y_las_subimos_al_apartado_archivos"
                    ? "images"
                    : o.key ===
                      "Si__subimos_las_grabaciones__pero_no_se_ve_lo_sucedido"
                    ? "notSeen"
                    : o.key === "No__tenemos_testigos_y_no_necesitamos_imagenes"
                    ? "witnesses"
                    : "noCameras",
              })),
              "caseWithImages"
            );
          },
          "pie"
        ),
      });

      reports.push({ type: "page-break" });

      //Intrusiones
      const rawIntrusionCases = this.cases.filter(
        (c) =>
          !!c.penalType &&
          c.penalType.length > 0 &&
          c.penalType.some((p) => p.id === "Sl6pxvKHjMFkqMzXwmcC")
      );

      const intrusionsByDateAndEstablshment = new Map();
      rawIntrusionCases.forEach((c) => {
        const date = moment(c.datetime.toDate()).format("DD/MM/YYYY");
        const key = `${date}-${c.establishment.id}`;
        if (intrusionsByDateAndEstablshment.has(key)) {
          intrusionsByDateAndEstablshment.get(key).amount += c.ticketAmount;
        } else {
          intrusionsByDateAndEstablshment.set(key, {
            dateKey: moment(c.datetime.toDate()).format("YYYY-MM-DD"),
            establishment: c.establishment.alias,
            date,
            amount: c.ticketAmount || 0,
          });
        }
      });

      const formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
      });

      const intrusionCases = [...intrusionsByDateAndEstablshment.values()].sort(
        (a, b) => a.dateKey.localeCompare(b.dateKey)
      );

      const intrusionsByDate = new Map();
      rawIntrusionCases.forEach((c) => {
        // const key = moment(c.datetime.toDate()).format("DD/MM/YYYY");
        const key = moment(c.datetime.toDate())
          .set("millisecond", 0)
          .set("second", 0)
          .set("minute", 0)
          .set("hour", 0)
          .toDate();
        let val = intrusionsByDate.get(key) || 0;

        intrusionsByDate.set(key, val + c.ticketAmount);
      });

      const intrusionCasesGraph = [...intrusionsByDate.keys()]
        .map((date) => ({
          date,
          amount: intrusionsByDate.get(date) || 0,
        }))
        .sort((a, b) => a.date.getTime() - b.date.getTime());

      reports.push({
        id: "amountPerIntrusions",
        size: "2/3",
        PDFSize: "1/1",

        ...ReportGenerator.generateLineReport(
          this.$t("analytics.titles.reports.amountPerIntrusions"),
          intrusionCasesGraph,
          (c) => ({ y: c.amount, x: c.date }),
          (v) => moment(v).locale(this.$i18n.locale).format("DD MMM YY"),
          (v) => formatter.format(v)
        ),
      });

      reports.push({
        id: "intrusions",
        size: "1/3",
        PDFSize: "1/1",

        ...ReportGenerator.tableReport(
          intrusionCases,
          this.translate(
            [
              { id: "establishment", size: "1/2" },
              { id: "date", size: "1/4" },
              { id: "amount", size: "1/4" },
            ],
            "intrusions"
          ),
          (c) => ({ ...c, amount: formatter.format(c.amount) })
        ),
      });

      return reports.map((r) => ({
        ...r,
        title: r.id
          ? this.$t(`analytics.titles.reports.${r.id}`, {
              name: this.$t(
                `analytics.labels.types.${
                  this.isForIssues ? "issues" : "cases"
                }`
              ),
            })
          : "",
      }));
    },
  },
  created() {
    this.$bind(
      "bands",
      db.collection(this.isForIssues ? "issuesBands" : "casesBands"),
      { wait: true }
    );
    this.$bind(
      "witnesses",
      db.collection("witnesses").where("deleted", "==", false),
      { wait: true }
    );
  },
  watch: {
    "$route.meta.isForIssues"() {
      this.selectedCustomer = null;
      this.useZones = true;
      this.selectedZones = [];
      this.selectedEstablishments = [];
      this.startDate = null;
    },
    selectedCustomer() {
      if (!this.zones.length) {
        this.useZones = false;
      }
      this.selectedZones = [];
      this.selectedEstablishments = [];
    },
    useZones() {
      this.selectedZones = [];
      this.selectedEstablishments = [];
    },
  },
  data() {
    return {
      useZones: true,
      selectedCustomer: null,
      selectedZones: [],
      selectedEstablishments: [],
      startDate: null,
      endDate: null,
      witnesses: null,
      bands: null,
    };
  },
  methods: {
    enumTranslate(enumType, key) {
      return this.$t(`enums.${enumType}.${key}`);
    },
    translate(list, key) {
      return list.map((x) => ({
        ...x,
        title: this.$t(`analytics.labels.reports.${key}.${x.id}`, {
          name: this.$t(
            `analytics.labels.types.${this.isForIssues ? "issues" : "cases"}`
          ),
        }),
      }));
    },
  },
};
</script>
<style lang="scss">
.datetime-remove-button {
  position: absolute;
  margin-left: -2rem;
  border: 0;
  background-color: transparent;
  margin-top: 0.75rem;
  cursor: pointer;
}
</style>
