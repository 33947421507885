var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.cases && _vm.bands && _vm.witnesses)?_c('div',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-base sm:w-1/1"},[_c('vx-card',{attrs:{"title":_vm.$t('analytics.titles.filter')}},[(_vm.isAdminOrManager)?[_c('span',[_vm._v(_vm._s(_vm.$t("analytics.fields.customer")))]),_c('v-select',{staticClass:"mb-4",attrs:{"label":"alias","placeholder":_vm.$t('analytics.placeholders.customer'),"options":_vm.customers,"clearable":true,"reduce":function (option) { return ({ id: option.id, alias: option.alias }); },"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"name":"customer"},model:{value:(_vm.selectedCustomer),callback:function ($$v) {_vm.selectedCustomer=$$v},expression:"selectedCustomer"}})]:_vm._e(),((_vm.isAdminOrManager && !!_vm.selectedCustomer) || _vm.isSuperCustomer)?[(_vm.zones.length)?_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"mb-4 md:mb-4",attrs:{"reduce":function (option) { return option.value; },"options":[
              { label: _vm.$t('analytics.titles.filterByZones'), value: true },
              {
                label: _vm.$t('analytics.titles.filterByEstablishments'),
                value: false,
              } ],"clearable":false,"name":"useZones"},model:{value:(_vm.useZones),callback:function ($$v) {_vm.useZones=$$v},expression:"useZones"}}):_vm._e(),(_vm.zones.length && _vm.useZones)?[_c('span',[_vm._v(_vm._s(_vm.$t("analytics.fields.zones")))]),_c('v-select',{staticClass:"mb-4",attrs:{"label":"alias","placeholder":_vm.$t('analytics.placeholders.zones'),"options":_vm.zones,"multiple":true,"clearable":true,"reduce":function (option) { return ({ id: option.id, alias: option.alias }); },"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"name":"zones"},model:{value:(_vm.selectedZones),callback:function ($$v) {_vm.selectedZones=$$v},expression:"selectedZones"}})]:[_c('span',[_vm._v(_vm._s(_vm.$t("analytics.fields.establishments")))]),_c('v-select',{staticClass:"mb-4",attrs:{"label":"alias","placeholder":_vm.$t('analytics.placeholders.establishments'),"options":_vm.establishments,"multiple":true,"clearable":true,"reduce":function (option) { return ({ id: option.id, alias: option.alias }); },"dir":_vm.$vs.rtl ? 'rtl' : 'ltr',"name":"establishments"},model:{value:(_vm.selectedEstablishments),callback:function ($$v) {_vm.selectedEstablishments=$$v},expression:"selectedEstablishments"}})]]:_vm._e(),_c('span',[_vm._v(_vm._s(_vm.$t("analytics.fields.period")))]),_c('div',{staticClass:"vx-row mb-4"},[_c('datetime',{staticClass:"vx-col sm:w-1/1 w-1/2",attrs:{"type":"date","input-class":"flatpickr-input vs-input--input","format":"dd-LL-yyyy","phrases":{
              ok: _vm.$t('buttons.ok'),
              cancel: _vm.$t('buttons.cancel'),
            },"minute-step":5,"zone":"UTC","name":"startDate","data-vv-as":_vm.$t('analytics.placeholders.startDate'),"placeholder":_vm.$t('analytics.placeholders.startDate')},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}},[_c('template',{slot:"after"},[((_vm.startDate || null) !== null)?_c('button',{staticClass:"datetime-remove-button",on:{"click":function($event){_vm.startDate = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon","svgClasses":"h-4 w-4"}})],1):_vm._e()])],2),_c('datetime',{staticClass:"vx-col sm:w-1/1 w-1/2",attrs:{"type":"date","input-class":"flatpickr-input vs-input--input","format":"dd-LL-yyyy","phrases":{
              ok: _vm.$t('buttons.ok'),
              cancel: _vm.$t('buttons.cancel'),
            },"minute-step":5,"zone":"UTC","name":"endDate","data-vv-as":_vm.$t('analytics.placeholders.endDate'),"placeholder":_vm.$t('analytics.placeholders.endDate')},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}},[_c('template',{slot:"after"},[((_vm.endDate || null) !== null)?_c('button',{staticClass:"datetime-remove-button",on:{"click":function($event){_vm.endDate = null}}},[_c('feather-icon',{attrs:{"icon":"XIcon","svgClasses":"h-4 w-4"}})],1):_vm._e()])],2)],1),_c('vs-button',{attrs:{"color":"primary","icon-pack":"feather","icon":"icon-bar-chart"},on:{"click":function($event){return _vm.$refs.html2Pdf.generatePdf()}}},[_vm._v(" "+_vm._s(_vm.$t("analytics.buttons.exportPDF"))+" ")])],2)],1)]),_c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":800,"filename":_vm.pdfFileName,"pdf-quality":2,"manual-pagination":true,"pdf-format":"a4","pdf-orientation":"portrait","pdf-content-width":"20cm"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('div',{staticClass:"vx-row mx-4 text-xs"},[_c('div',{staticClass:"vx-col w-full mb-base sm:w-1/1 md:w-1/1 text-center my-4"},[_c('h1',[_vm._v(_vm._s(_vm.pdfFileName))])]),_vm._l((_vm.reports),function(report,pos){return [(report.type === 'page-break')?[_c('div',{key:("pdf_report_separator_" + pos),staticClass:"w-full html2pdf__page-break"}),_c('div',{key:("pdf_report_margin_" + pos),staticClass:"w-full mb-22"},[_vm._v("   ")])]:_c('Report',{key:("pdf_report_" + pos),attrs:{"report":report,"isForPDF":true}})]})],2)])]),_c('div',{staticClass:"vx-row"},_vm._l((_vm.reports.filter(function (x) { return x.type !== 'page-break'; })),function(report,pos){return _c('Report',{key:("report_" + pos),attrs:{"report":report}})}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }