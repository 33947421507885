<template>
  <div
    class="vx-col w-full sm:w-1/1 pdf-item"
    :class="[
      `md:w-${isForPDF && report.PDFSize ? report.PDFSize : report.size}`,
      isForPDF ? 'my-2' : 'mb-base',
    ]"
  >
    <statistics-card-line
      v-if="report.type === 'widget'"
      :isForPDF="isForPDF"
      :icon="report.icon"
      :statistic="report.value | k_formatter"
      :statisticTitle="report.title"
      :hideChart="true"
      :color="report.color"
    />

    <statistics-card-line
      v-else-if="report.type === 'area'"
      :isForPDF="isForPDF"
      :icon="report.icon"
      :statistic="report.value | k_formatter"
      :statisticTitle="report.title"
      :chartData="report.series"
      :color="report.color"
      type="area"
    />

    <vs-card v-else-if="['donut', 'pie', 'line'].includes(report.type)">
      <h3 slot="header" :class="isForPDF ? '-my-3 text-lg' : 'py-3'">
        {{ report.title }}
      </h3>
      <vue-apex-charts
        :class="{ 'mt-6 mb-8 py-3': !isForPDF }"
        :type="report.type"
        :height="isForPDF ? 300 : 325"
        :options="report.options"
        :series="report.series"
        v-if="report.series.find((s) => !!s)"
      />
      <div v-else class="mt-6 mb-8 text-center" style="height: 330px">
        <feather-icon
          icon="AlertOctagonIcon"
          svgClasses="h-24 w-24"
          class="mt-20 mr-2"
        />
        <h2>{{ $t("analytics.labels.noData") }}</h2>
      </div>
    </vs-card>

    <vs-card v-else-if="report.type === 'bar'">
      <h3 slot="header" :class="isForPDF ? '-my-3 text-lg' : 'py-3'">
        {{ report.title }}
      </h3>
      <template v-if="report.total">
        <div
          v-for="(serie, index) in report.series"
          :key="`${report.id}_serie_${index}`"
          :class="{ 'mt-1': index }"
        >
          <div class="flex justify-between">
            <div class="flex flex-row">
              <span class="mr-2">{{ serie.title }}</span>
              <h4 class="text-base">
                {{ serie.value }}
                ({{
                  `${
                    Math.round((serie.value * 10000) / report.total) / 100.0
                  }%`
                }})
              </h4>
            </div>
          </div>
          <vs-progress
            :percent="(serie.value * 100.0) / report.total"
          ></vs-progress>
        </div>
      </template>
      <div v-else class="mt-6 mb-8 text-center" style="height: 330px">
        <feather-icon
          icon="AlertOctagonIcon"
          svgClasses="h-24 w-24"
          class="mt-20 mr-2"
        />
        <h2>{{ $t("analytics.labels.noData") }}</h2>
      </div>
    </vs-card>

    <div class="vx-row" v-else-if="report.type === 'table'">
      <div
        v-for="col in report.cols"
        :key="col.id"
        class="vx-col w-full sm:w-1/1 pdf-item"
        :class="`md:w-${col.size}`"
      >
        <b>{{ col.title }}</b>
      </div>
      <template v-for="(val, pos) in report.values">
        <div
          v-for="col in report.cols"
          :key="`val_${col.id}_${pos}`"
          class="vx-col w-full sm:w-1/1 pdf-item"
          :class="`md:w-${col.size}`"
        >
          {{ val[col.id] }}
        </div>
      </template>
    </div>

    <div class="vx-row" v-else-if="report.type === 'container'">
      <Report
        :report="report"
        :key="`report_${pos}`"
        :isForPDF="isForPDF"
        v-for="(report, pos) in report.reports"
      />
    </div>
    <h1 v-else :key="report.id">SIN HACER {{ report.type }}</h1>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
import StatisticsCardLine from "@/components/statistics-cards/StatisticsCardLine.vue";
import FeatherIcon from "@/components/FeatherIcon.vue";

export default {
  name: "Report",
  props: ["report", "isForPDF"],
  components: {
    VueApexCharts,
    StatisticsCardLine,
    FeatherIcon,
  },
};
</script>
