import moment from "moment";
import crimeTypes from "@/data/cases/lists/crimeTypes.json";
import penalTypes from "@/data/cases/lists/penalTypes.json";
import caseStatuses from "@/data/cases/lists/caseStatuses.json";
import { getMangoFields } from "@/common/mango-custom-fields.js";

const randomColor = () => {
  var r = Math.floor(Math.random() * 255);
  var g = Math.floor(Math.random() * 255);
  var b = Math.floor(Math.random() * 255);
  return "rgb(" + r + "," + g + "," + b + ")";
};

export const generateWidgetReport = (cases, filterCriteria) => {
  let filteredCases = filterCriteria
    ? cases.filter((c) => filterCriteria(c))
    : cases;

  return filteredCases.length;
};

export const generateAreaReport = (cases, filterCriteria, groupCriteria) => {
  let filteredCases = filterCriteria
    ? cases.filter((c) => filterCriteria(c))
    : cases;

  let series = [];
  filteredCases.forEach((c) => {
    let currentDate = series.length ? series[series.length - 1] : null;
    if (currentDate && groupCriteria(c, currentDate.entity)) {
      //Seguimos en la misma fecha
      series[series.length - 1].value += 1;
    } else {
      //Es una nueva fecha
      series.push({ entity: c, value: 1 });
    }
  });

  let value = filteredCases.length;

  return { series: series.map((s) => s.value), value };
};

export const generateDonutReport = (
  cases,
  options,
  filterCriteria,
  groupCriteria,
  valueCriteria,
  valueFormatter,
  type = "donut"
) => {
  let filteredCases = filterCriteria
    ? cases.filter((c) => filterCriteria(c))
    : cases;

  let series = options.map(() => 0);
  filteredCases.forEach((c) => {
    const pos = groupCriteria(c);
    const value = valueCriteria ? valueCriteria(c) : 1;
    series[pos - 1] += value;
  });

  const optionsWithData = options.filter((x, i) => series[i] > 0);

  series = series.filter((x) => x > 0);

  let chartOptions = {
    labels: optionsWithData.map((o) => o.title),
    dataLabels: {
      formatter(val, opts) {
        const total = opts.w.config.series[opts.seriesIndex];
        return [
          valueFormatter ? valueFormatter(total) : total,
          `(${val.toFixed(2)} %)`,
        ];
      },
      style: {
        colors: optionsWithData.map((o) => o.color),
      },
      background: {
        padding: 8,
        enabled: true,
        color: "red",
      },
    },
    legend: { show: true, position: "bottom" },
    chart: {
      type,
      toolbar: {
        show: false,
      },
    },
    stroke: { width: 0 },
    colors: optionsWithData.map((o) => o.color),
    plotOptions: {
      pie: {
        offsetY: 20,
        dataLabels: {
          offset: 30,
        },
      },
    },
    tooltip: {
      custom: ({ series, seriesIndex, w }) => {
        const color = w.config.colors[seriesIndex];
        const name = w.config.labels[seriesIndex];
        const val = series[seriesIndex];
        const per = val
          ? ((val / series.reduce((a, b) => a + b, 0)) * 100.0).toFixed(2)
          : 0;
        return `<div class="p-2" style="background-color: ${color};">${name}: ${
          valueFormatter ? valueFormatter(val) : val
        } (${per} %)</div>`;
      },
    },
  };

  return { options: chartOptions, series };
};

export const generateLineReport = (
  title,
  cases,
  dataMapper,
  titleFormatter,
  valueFormatter
) => {
  const series = [
    { name: title, data: cases.map(dataMapper), color: "rgb(115,103,240)" },
  ];

  let chartOptions = {
    chart: {
      type: "line",
      stacked: false,
      zoom: { enabled: false },
      toolbar: false,
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: { curve: "smooth" },
    yaxis: {
      labels: {
        formatter: (val) => valueFormatter(val),
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: (val) => titleFormatter(val),
      },
    },
    tooltip: {
      shared: false,
    },
  };

  return { type: "line", options: chartOptions, series };
};

export const generateBarReport = (
  cases,
  options,
  filterCriteria,
  groupCriteria
) => {
  let filteredCases = filterCriteria
    ? cases.filter((c) => filterCriteria(c))
    : cases;

  let series = [];
  options.forEach((o, pos) => {
    series.push({
      title: o.title,
      value: filteredCases.filter((c) => groupCriteria(c, pos + 1)).length,
    });
  });

  let total = series.map((x) => x.value).reduce((a, b) => a + b, 0);

  return { total, series };
};

export const hoursReport = (cases, options) => {
  let criteria = (c, typePos) => {
    let hour = moment(c.datetime.toDate()).hours();
    if (typePos === 1) {
      //Mañana
      return hour >= 7 && hour < 13;
    } else if (typePos === 2) {
      //Mediodia
      return hour >= 13 && hour < 16;
    } else if (typePos === 3) {
      //Tarde
      return hour >= 16 && hour < 22;
    } else if (typePos === 4) {
      //Noche
      return hour >= 22 || hour < 7;
    }
  };

  let data = generateBarReport(cases, options, null, criteria);
  return {
    type: "bar",
    series: data.series,
    total: data.total,
  };
};

export const crimeTypesReport = (cases, titleGenerator) => {
  let options = crimeTypes.map((x) => ({
    id: x.id,
    key: x.key,
    title: titleGenerator("crimeTypes", x.key),
  }));

  let criteria = (c, typePos) => {
    return c.crimeType.id === options[typePos - 1].id;
  };

  let data = generateBarReport(cases, options, (c) => c.crimeType, criteria);
  return {
    type: "bar",
    series: data.series,
    total: data.total,
  };
};

export const penalTypesReport = (cases, titleGenerator) => {
  let options = penalTypes
    .map((x) => ({ title: titleGenerator("penalTypes", x.key) }))
    .sort((a, b) => a.title.localeCompare(b.title));

  let criteria = (c, typePos) => {
    let penalType = Array.isArray(c.penalType) ? c.penalType : [c.penalType];
    return penalType.map((x) => x.alias).includes(options[typePos - 1].title);
  };

  let data = generateBarReport(cases, options, (c) => !!c.penalType, criteria);
  return {
    type: "bar",
    series: data.series,
    total: data.total,
  };
};

export const witnessReport = (cases, witnesses, options) => {
  let criteria = (c) => {
    let hasWitnesses = witnesses.some((w) => (w.cases || []).includes(c.id));
    if (hasWitnesses) {
      return 1;
    } else {
      return 2;
    }
  };
  let data = generateDonutReport(
    cases,
    options,
    null,
    criteria,
    null,
    null,
    "pie"
  );

  return {
    type: "pie",
    options: data.options,
    series: data.series,
  };
};

export const tableReport = (cases, cols, valuesTransformer) => {
  const values = cases.map((x) => valuesTransformer(x));
  return {
    type: "table",
    cols,
    values,
  };
};

export const statusReport = (customerId, cases, titleGenerator) => {
  let options = caseStatuses
    .filter(
      (x) => !x.customers || !customerId || x.customers.includes(customerId)
    )
    .map((x) => ({ ...x, title: titleGenerator("caseStatuses", x.key) }));

  let criteria = (c, typePos) => {
    return c.caseStatus.id === options[typePos - 1].id;
  };

  let data = generateBarReport(cases, options, (c) => !!c.caseStatus, criteria);
  return {
    type: "bar",
    series: data.series,
    total: data.total,
  };
};

export const customDonutReport = (cases, attribute, optionsProcessor, type) => {
  let filteredCases = cases.filter((c) => !!c[attribute]);

  let mangoCustomField = null;
  getMangoFields({}).forEach((block) => {
    block.forEach((field) => {
      if (field.attribute === attribute) {
        mangoCustomField = field;
      }
    });
  });

  let options = optionsProcessor(mangoCustomField.extra.options).map((o) => ({
    ...o,
    color: randomColor(),
  }));

  let criteria = (c) => {
    let index = options.findIndex(
      (x) => x.realId === c[attribute] || x.realId === c[attribute].id
    );
    return index + 1;
  };
  let data = generateDonutReport(
    filteredCases,
    options,
    null,
    criteria,
    null,
    null,
    type
  );

  return {
    type,
    options: data.options,
    series: data.series,
  };
};
